// ? These components function with alpine + tailwind UI components
import Swiper from 'swiper'
import {Autoplay, EffectCreative, FreeMode, Mousewheel, Navigation, Pagination} from 'swiper/modules'
// import {ParallaxComponent, QuickContactComponent} from "../../types/obaia-window";

window.obaia.Components = window.obaia.Components || {}

window.obaia.Components.videoPlayer = (options) => {
    return {
        videoVisible: false,
        videoSrc: null,
        // init() {
        //     this.$nextTick(()=> {
        //         let parser = new DOMParser()
        //         let doc = parser.parseFromString(this.$refs.iframewrapper.innerText, 'text/html')
        //         let iframeSrc = doc.querySelector('iframe').getAttribute('src') + '&autoplay=1'
        //         this.videoSrc = iframeSrc
        //     })
        // }
    }
}

window.obaia.Components.fetchApi = (options) => {
    return {
        section: options.section ?? '',
        url: options.url ?? '',
        search: options.search ?? '',
        entries: [],
        filterDoelgroep: options.filterDoelgroep ?? [],
        filterType: [],
        entryType: null,
        currentPage: 1,
        totalPages: null,
        limit: options.limit ?? 5,
        offset: null,
        results: null,
        orderBy: null,
        loading: true,
        query: null,
        init() {
            this.fetchData()
        },
        queryBuilder() {
            if (this.section === 'handigeInformatie') {
                this.query = `
                ... on handigeInformatie_default_Entry {
                titel
                  filter {
                    title
                    id
                  }
                  hero {
                    id
                    url(format: "webp", width: 380, height: 320, quality: 80)
                    title
                  }
                  video {
                    title
                    description
                    url
                    type
                    image
                    imageWidth
                    imageHeight
                    code
                    width
                    height
                    aspectRatio
                    authorName
                    authorUrl
                    providerName
                    providerUrl
                  }
                  button {
                    ariaLabel
                    customText
                    target
                    text
                    title
                    type
                    url
                  }
                }
                `
            }
        },
        fetchData() {
            this.loading = true

            this.queryBuilder()

            let relatedTo
            const relatedToSubject = this.filterDoelgroep.length > 0 ? [{"id": this.filterDoelgroep}] : null
            const relatedToType = this.filterType.length > 0 ? [{"id": this.filterType}] : null
            if (relatedToSubject !== null) {
                relatedTo = relatedToSubject
            }
            if (relatedToType !== null) {
                relatedTo = relatedToType
            }
            if (relatedToSubject !== null && relatedToType !== null) {
                relatedTo = [
                    {"id": this.filterDoelgroep},
                    {"id": this.filterType}
                ]
            }
            fetch(this.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                        query: `
                            query Entries($section: [String!], $relatedTo: [EntryCriteriaInput], $search: String, $limit: Int, $offset: Int, $orderBy: String) {
                              entries(section: $section, relatedToEntries: $relatedTo, search: $search, limit: $limit, offset: $offset, orderBy: $orderBy) {
                                id
                                title
                                url
                                typeHandle
                                ${this.query}
                              }
                              entryCount(section: $section, relatedToEntries: $relatedTo, search: $search)
                            }`,
                        variables: {
                            "section": this.section,
                            "relatedTo": relatedTo,
                            "search": this.search ?? null,
                            "limit": this.limit,
                            "offset": this.offset,
                            "orderBy": this.orderBy ?? 'lft',
                        }
                    }
                )
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok')
                    }
                    return response.json()
                })
                .then(data => {
                    console.log(data)
                    this.entries = data?.data?.entries
                    this.results = data?.data?.entryCount
                    this.totalPages = Math.ceil(data?.data?.entryCount / this.limit) || 1
                    this.loading = false
                })
                .catch(error => console.error('Error:', error))
        },
        // pagination
        calculateOffset() {
            this.offset = (this.currentPage - 1) * this.limit
            this.fetchData()
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++
                this.calculateOffset()
            }
            this.fetchData()
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--
                this.calculateOffset()
            }
            this.fetchData()
        },
        resetPage() {
            if (this.$el.type === 'radio') {
                if (this.projectstatus === this.$el.value) {
                    this.$el.checked = false
                    this.projectstatus = ''
                } else {
                    this.$el.checked = true
                    this.projectstatus = this.$el.value
                }
            }
            this.currentPage = 1
            this.calculateOffset()
        },
    }
}

window.obaia.Components.quickContact = function quickContact({open = false} = {}) {
    return {
        isSmallScreen: window.innerWidth <= 768, // Adjust this value to your specific breakpoint
        open,

        init() {
            this.adjustView()
            window.addEventListener('resize', () => {
                this.adjustView()
            });
        },
        adjustView() {
            this.isSmallScreen = window.innerWidth <= 768 // Adjust this value to your specific breakpoint
            this.open = false
        },
        toggleOpen() {
            this.open = !this.open
        }
    }
}

window.obaia.Components.searchOverlay = (options) => {
    return {
        search: options.search ?? null,
        section: options.section ?? null,
        results: [],
        searchOpen: options.searchOpen ?? false,
        searchOverlay: options.searchOverlay ?? false,
        init() {
            // this.$refs.search.focus()
            this.fetch()
        },
        fetch(searchValue) {
            // this.search = searchValue
            if (this.search.length > 2) {
                const data = {q: this.search, section: this.section}
                fetch('/actions/site-module/search', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': window.obaia.csrf['csrfTokenValue'],
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json()
                    })
                    .then(data => {
                        this.results = data?.results
                        this.loading = false
                    })
                    .catch(error => {
                            console.error(error)
                        }
                    )
                this.searchOpen = true
            } else {
                this.searchOpen = false
            }
        },
        isOpen() {
            // return this.searchOpen && this.results.length > 0
            return this.searchOpen
        },
        onEscape() {
            this.searchOpen = false
            this.searchOverlay = false
            this.results = []
            this.search = ''
        },
        scrollToSection(url) {
            const id = url.split("#")[1]
            const section = document.getElementById(id)
            if (section) {
                setTimeout(() => {
                    section.scrollIntoView({behavior: 'smooth'})
                }, 170);
            } else {
                window.location.href = url
            }
        }
    }
}

window.obaia.Components.parallax = (options) => {
    let lastScrollY = 0;
    let ticking = false;
    const parallaxElements = []
    document.addEventListener('scroll', () => {
        lastScrollY = window.scrollY
        if (!ticking) {
            window.requestAnimationFrame(() => {
                updateParallax(lastScrollY)
                ticking = false
            })
            ticking = true
        }
    })

    function updateParallax(scrollY) {
        parallaxElements.forEach(el => {
            // if (el.parallax) { // Only calculate for elements in view
            let distanceToElementTop = el.$el.offsetTop
            let translateValue = (distanceToElementTop - scrollY) * (el.speed / 100)
            // You can add more conditions based on direction and horizontal properties here
            if (el.direction === 'up') {
                el.$el.style.setProperty('--transform-parallax', `${-translateValue}px`)
            } else {
                el.$el.style.setProperty('--transform-parallax', `${translateValue}px`)
            }
            // }
        })
    }

    return {
        parallax: false,
        speed: options.speed ?? 10,
        direction: options.direction ?? 'down',
        horizontal: options.horizontal ?? false,
        init() {
            // Register this component in the global parallaxElements array
            parallaxElements.push(this)
        }
    }
}

window.obaia.Components.swiper = (options) => {
    return {
        swiperInstance: null,

        init() {
            let initialSlideIndex = 0
            // if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
            //     initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
            // }
            const thisElem = this.$el

            this.swiperInstance = new Swiper(thisElem, {
                modules: [Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode],
                autoplay: options.autoplay ?? false,
                speed: options.speed ?? 300,
                freeMode: options.freeMode ?? false,
                // cssMode: options.cssMode ?? false,
                grabCursor: options.grabCursor ?? false,
                loop: options.loop ?? false,
                autoHeight: options.autoHeight ?? false,
                slidesPerView: options.slidesPerView ?? 1,
                centeredSlides: options.centeredSlides ?? false,
                // centeredSlidesBounds: true,
                spaceBetween: options.spaceBetween ?? 16,
                initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                mousewheel: {
                    forceToAxis: true,
                },
                navigation: {
                    prevEl: this.$refs.swiperPrev ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-prev') ?? undefined),
                    // prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                    nextEl: this.$refs.swiperNext ?? (thisElem.closest('section')?.querySelector('.js-swiper-button-next') ?? undefined),
                },
                pagination: options.pagination ? {
                    el: thisElem.closest('section')?.querySelector('.js-swiper-pagination') | null,
                    clickable: true,
                    renderBullet: function (index, className) {
                        let names = []
                        thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                            names.push(slide.getAttribute('data-name') || "")
                        })
                        return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
                    }
                } : {},
                breakpoints: {
                    ...options.breakpoints
                },
            })
        }
    }
}

window.obaia.Components.popoverGroup = function popoverGroup() {
    return {
        __type: 'popoverGroup',
        init() {
            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                if (e.target instanceof Element && !this.$el.contains(e.target)) {
                    window.dispatchEvent(
                        new CustomEvent('close-popover-group', {
                            detail: this.$el,
                        })
                    )
                }
            }
            window.addEventListener('focus', handler, true)
        },
    }
}

window.obaia.Components.popover = function popover({open = false, focus = false} = {}) {
    const focusableSelector = [
        '[contentEditable=true]',
        '[tabindex]',
        'a[href]',
        'area[href]',
        'button:not([disabled])',
        'iframe',
        'input:not([disabled])',
        'select:not([disabled])',
        'textarea:not([disabled])',
    ]
        .map((selector) => `${selector}:not([tabindex='-1'])`)
        .join(',')

    function focusFirst(container) {
        const focusableElements = Array.from(container.querySelectorAll(focusableSelector))

        function tryFocus(element) {
            if (element === undefined) return

            if (element instanceof HTMLElement) {
                element.focus({preventScroll: true})
            }

            if (document.activeElement !== element) {
                tryFocus(focusableElements[focusableElements.indexOf(element) + 1])
            }
        }

        tryFocus(focusableElements[0])
    }

    return {
        __type: 'popover',
        open,
        init() {
            if (focus) {
                this.$watch('open', (open) => {
                    if (open) {
                        this.$nextTick(() => {
                            focusFirst(this.$refs.panel)
                        })
                    }
                })
            }

            let handler = (e) => {
                if (!document.body.contains(this.$el)) {
                    window.removeEventListener('focus', handler, true)
                    return
                }
                let ref = focus ? this.$refs.panel : this.$el
                if (this.open && e.target instanceof Element && !ref.contains(e.target)) {
                    let node = this.$el
                    while (node.parentNode) {
                        node = node.parentNode
                        if (node.__x instanceof this.constructor) {
                            if (node.__x.$data.__type === 'popoverGroup') return
                            if (node.__x.$data.__type === 'popover') break
                        }
                    }
                    this.open = false
                }
            }

            window.addEventListener('focus', handler, true)
        },
        onEscape() {
            this.open = false
            if (this.restoreEl) {
                this.restoreEl.focus()
            }
        },
        onClosePopoverGroup(e) {
            if (e.detail.contains(this.$el)) {
                this.open = false
            }
        },
        toggle(e) {
            this.open = !this.open
            if (this.open) {
                if (e.currentTarget instanceof HTMLElement) {
                    this.restoreEl = e.currentTarget;
                } else {
                    this.restoreEl = undefined;
                }
            } else if (this.restoreEl) {
                // this.restoreEl.focus()
                this.restoreEl.focus()
            }
        },
    }
}

// window.obaia.Components.radioGroup = function radioGroup({initialCheckedIndex = 0} = {}) {
//     return {
//         value: undefined,
//         active: undefined,
//         init() {
//             let options = Array.from(this.$el.querySelectorAll('input'))
//
//             this.value = options[initialCheckedIndex]?.value
//
//             for (let option of options) {
//                 option.addEventListener('change', () => {
//                     this.active = option.value
//                 })
//                 option.addEventListener('focus', () => {
//                     this.active = option.value
//                 })
//             }
//
//             window.addEventListener(
//                 'focus',
//                 () => {
//                     console.log('Focus change')
//                     if (!options.includes(document.activeElement)) {
//                         console.log('HIT')
//                         this.active = undefined
//                     }
//                 },
//                 true
//             )
//         },
//     }
// }
//
// window.obaia.Components.tabs = function tabs() {
//     return {
//         selectedIndex: 0,
//         onTabClick(event) {
//             if (!this.$el.contains(event.detail)) return
//
//             let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//             let panels = Array.from(this.$el.querySelectorAll('[x-data^="Components.tabPanel("]'))
//
//             let idx = tabs.indexOf(event.detail)
//             this.selectedIndex = idx
//
//             window.dispatchEvent(
//                 new CustomEvent('tab-select', {
//                     detail: {
//                         tab: event.detail,
//                         panel: panels[idx],
//                     },
//                 })
//             )
//         },
//         onTabKeydown(event) {
//             if (!this.$el.contains(event.detail.tab)) return
//
//             let tabs = Array.from(this.$el.querySelectorAll('[x-data^="Components.tab("]'))
//             let tabIndex = tabs.indexOf(event.detail.tab)
//
//             if (event.detail.key === 'ArrowLeft') {
//                 this.onTabClick({ detail: tabs[(tabIndex - 1 + tabs.length) % tabs.length] })
//             } else if (event.detail.key === 'ArrowRight') {
//                 this.onTabClick({ detail: tabs[(tabIndex + 1) % tabs.length] })
//             } else if (event.detail.key === 'Home' || event.detail.key === 'PageUp') {
//                 this.onTabClick({ detail: tabs[0] })
//             } else if (event.detail.key === 'End' || event.detail.key === 'PageDown') {
//                 this.onTabClick({ detail: tabs[tabs.length - 1] })
//             }
//         },
//     }
// }
//
// window.obaia.Components.tab = function tab(defaultIndex = 0) {
//     return {
//         selected: false,
//         init() {
//             let tabs = Array.from(
//                 this.$el
//                     .closest('[x-data^="Components.tabs("]')
//                     .querySelectorAll('[x-data^="Components.tab("]')
//             )
//             this.selected = tabs.indexOf(this.$el) === defaultIndex
//             this.$watch('selected', (selected) => {
//                 if (selected) {
//                     this.$el.focus()
//                 }
//             })
//         },
//         onClick() {
//             window.dispatchEvent(
//                 new CustomEvent('tab-click', {
//                     detail: this.$el,
//                 })
//             )
//         },
//         onKeydown(event) {
//             if (['ArrowLeft', 'ArrowRight', 'Home', 'PageUp', 'End', 'PageDown'].includes(event.key)) {
//                 event.preventDefault()
//             }
//
//             window.dispatchEvent(
//                 new CustomEvent('tab-keydown', {
//                     detail: {
//                         tab: this.$el,
//                         key: event.key,
//                     },
//                 })
//             )
//         },
//         onTabSelect(event) {
//             this.selected = event.detail.tab === this.$el
//         },
//     }
// }
//
// window.obaia.Components.tabPanel = function tabPanel(defaultIndex = 0) {
//     return {
//         selected: false,
//         init() {
//             let panels = Array.from(
//                 this.$el
//                     .closest('[x-data^="Components.tabs("]')
//                     .querySelectorAll('[x-data^="Components.tabPanel("]')
//             )
//             this.selected = panels.indexOf(this.$el) === defaultIndex
//         },
//         onTabSelect(event) {
//             this.selected = event.detail.panel === this.$el
//         },
//     }
// }


// window.obaia.Components.menu = function menu(options = {open: false}) {
//     let pointer = useTrackedPointer()
//
//     return {
//         init(this: MenuComponent) {
//             this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]'))
//             this.$watch('open', () => {
//                 if (this.open) {
//                     this.activeIndex = -1
//                 }
//             })
//         },
//         activeDescendant: null,
//         activeIndex: null,
//         items: null,
//         open: options.open,
//         focusButton() {
//             this.$refs.button.focus()
//         },
//         onButtonClick() {
//             this.open = !this.open
//             if (this.open) {
//                 this.$nextTick(() => {
//                     this.$refs['menu-items'].focus()
//                 })
//             }
//         },
//         onButtonEnter() {
//             this.open = !this.open
//             if (this.open) {
//                 this.activeIndex = 0
//                 this.activeDescendant = this.items[this.activeIndex].id
//                 this.$nextTick(() => {
//                     this.$refs['menu-items'].focus()
//                 })
//             }
//         },
//         onArrowUp() {
//             if (!this.open) {
//                 this.open = true
//                 this.activeIndex = this.items.length - 1
//                 this.activeDescendant = this.items[this.activeIndex].id
//
//                 return
//             }
//
//             if (this.activeIndex === 0) {
//                 return
//             }
//
//             this.activeIndex = this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1
//             this.activeDescendant = this.items[this.activeIndex].id
//         },
//         onArrowDown() {
//             if (!this.open) {
//                 this.open = true
//                 this.activeIndex = 0
//                 this.activeDescendant = this.items[this.activeIndex].id
//
//                 return
//             }
//
//             if (this.activeIndex === this.items.length - 1) {
//                 return
//             }
//
//             this.activeIndex = this.activeIndex + 1
//             this.activeDescendant = this.items[this.activeIndex].id
//         },
//         onClickAway($event) {
//             if (this.open) {
//                 const focusableSelector = [
//                     '[contentEditable=true]',
//                     '[tabindex]',
//                     'a[href]',
//                     'area[href]',
//                     'button:not([disabled])',
//                     'iframe',
//                     'input:not([disabled])',
//                     'select:not([disabled])',
//                     'textarea:not([disabled])',
//                 ]
//                     .map((selector) => `${selector}:not([tabindex='-1'])`)
//                     .join(',')
//
//                 this.open = false
//
//                 if (!$event.target.closest(focusableSelector)) {
//                     this.focusButton()
//                 }
//             }
//         },
//
//         onMouseEnter(evt) {
//             pointer.update(evt)
//         },
//         onMouseMove(evt, newIndex) {
//             // Only highlight when the cursor has moved
//             // Pressing arrow keys can otherwise scroll the container and override the selected item
//             if (!pointer.wasMoved(evt)) return
//             this.activeIndex = newIndex
//         },
//         onMouseLeave(evt) {
//             // Only unhighlight when the cursor has moved
//             // Pressing arrow keys can otherwise scroll the container and override the selected item
//             if (!pointer.wasMoved(evt)) return
//             this.activeIndex = -1
//         },
//     }
// }
// function useTrackedPointer() {
//     /** @type {[x: number, y: number]} */
//     let lastPos = [-1, -1]
//
//     return {
//         /**
//          * @param {PointerEvent} evt
//          */
//         wasMoved(evt) {
//             let newPos = [evt.screenX, evt.screenY]
//
//             if (lastPos[0] === newPos[0] && lastPos[1] === newPos[1]) {
//                 return false
//             }
//
//             lastPos = newPos
//             return true
//         },
//
//         /**
//          * @param {PointerEvent} evt
//          */
//         update(evt) {
//             lastPos = [evt.screenX, evt.screenY]
//         },
//     }
// }